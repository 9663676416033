import React from "react";
import "./../App.css";

const About = () => {
  return (
    <div className="Baner">
      <div class="logo">Abram7</div>
      <div class="menu">
        <a href="#option1" class="menu-item">O mnie</a>
        <a href="#option2" class="menu-item">Wykształcenie</a>
        <a href="#option3" class="menu-item">Doświadczenie</a>
        <a href="#option3" class="menu-item">Portfolio</a>
      </div>
      <div class="settings">Ustawienia</div>
    </div>
  );
};

export default About;
