import React from "react";
import About from "./About/About";
import "./App.css";
import Footer from "./About/Footer";
import SideMenu from "./About/SideMenu";

const App = () => {
  return (
    <div className="container">
      <About></About>
      <SideMenu></SideMenu>
      <div className="content">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tempor
        imperdiet enim, in dapibus mi congue nec. Mauris felis risus, aliquam
        eget porta et, pharetra sodales lorem. Vivamus sed mauris neque. Duis
        fermentum, nisi eget pellentesque vulputate, ipsum leo sodales mi, vitae
        auctor orci turpis eget sapien. Nulla gravida vehicula dui. Fusce eu
        aliquam ex, vel blandit tortor. Nunc porta non nibh pretium sodales.
        Maecenas ut orci vitae metus pharetra suscipit non efficitur nibh. Ut
        magna tellus, pellentesque eu dignissim a, hendrerit vitae purus. Sed id
        ipsum ultricies, imperdiet justo ut, aliquet tellus. Aenean eget nulla
        eget libero blandit rutrum. Vivamus vel sapien vel leo placerat
        sollicitudin. Praesent pharetra augue eget elementum bibendum. Phasellus
        iaculis luctus commodo. Vestibulum id tristique diam. Nulla finibus
        posuere lectus id mollis. Fusce congue magna eget sem sodales dignissim.
        In mollis sed nibh non dictum. Ut purus mauris, tempus id elit sed,
        blandit rhoncus ligula. Cras lacus nulla, suscipit sed cursus non,
        maximus et ipsum. Vestibulum non ultrices augue. Donec eu mauris
        imperdiet, congue est eu, pulvinar urna. Ut lectus ligula, bibendum sit
        amet neque eu, feugiat tincidunt est. Aliquam erat volutpat. Vivamus
        dictum ex tellus. Sed at augue at ante tempus mollis sed in quam. Nullam
        quis ullamcorper dui. Vivamus non eros sit amet erat tristique
        pellentesque. Vivamus vitae malesuada nisl, vestibulum tincidunt ipsum.
        Proin eu velit ac orci mollis finibus. Nullam vitae orci semper, mattis
        dui eget, rutrum augue. Nunc arcu lorem, finibus ac hendrerit ut,
        malesuada non tellus. Etiam ut molestie nulla. Ut sodales quam quam, in
        consequat orci aliquet sed. Aenean aliquet est quis urna pellentesque,
        eget tempus leo lobortis. Maecenas sed lectus faucibus, rhoncus augue
        et, cursus ex. In hac habitasse platea dictumst. Vestibulum efficitur id
        libero sed semper. Phasellus ac ante a dui hendrerit finibus.
        Pellentesque id fermentum tortor. Vestibulum ornare, arcu eu convallis
        hendrerit, ipsum tortor faucibus risus, sed laoreet metus massa eu dui.
        Sed feugiat molestie massa nec pulvinar. Nullam pretium, ligula vel
        eleifend interdum, felis erat rutrum neque, ac fermentum mi turpis sit
        amet metus. Pellentesque ut dapibus dui. Cras rutrum lectus nunc, eget
        consectetur quam eleifend in. Pellentesque placerat, mauris eu porta
        semper, massa tortor aliquet eros, ut mattis mi nunc vitae elit.
        Pellentesque laoreet laoreet purus, ut dignissim metus viverra non. In
        pretium imperdiet ornare. Nulla quis fringilla justo. Aenean ultrices,
        elit eget pulvinar lobortis, sem urna faucibus justo, ac bibendum tellus
        erat venenatis elit. Vivamus accumsan neque vel diam rhoncus, ut lacinia
        dolor cursus. Suspendisse porta ultrices maximus. Integer eget tristique
        tellus, quis ultricies orci. Sed sed augue maximus, dapibus turpis nec,
        bibendum mauris. Sed in condimentum metus. Etiam ut eleifend lorem.
        Pellentesque malesuada, velit at convallis imperdiet, ipsum tortor
        elementum augue, sit amet aliquet est quam a ligula. Cras euismod lacus
        vitae aliquam volutpat. Aliquam erat volutpat. Integer cursus in nunc
        eget imperdiet. Nulla et eleifend neque. Fusce tincidunt ante at ornare
        consequat. Cras eu imperdiet mauris. Vivamus a nulla ut sapien faucibus
        molestie sit amet ultrices risus. Morbi dignissim lorem quis tortor
        laoreet fermentum.Lorem ipsum dolor sit amet, consectetur adipiscing
        elit. Integer tempor imperdiet enim, in dapibus mi congue nec. Mauris
        felis risus, aliquam eget porta et, pharetra sodales lorem. Vivamus sed
        mauris neque. Duis fermentum, nisi eget pellentesque vulputate, ipsum
        leo sodales mi, vitae auctor orci turpis eget sapien. Nulla gravida
        vehicula dui. Fusce eu aliquam ex, vel blandit tortor. Nunc porta non
        nibh pretium sodales. Maecenas ut orci vitae metus pharetra suscipit non
        efficitur nibh. Ut magna tellus, pellentesque eu dignissim a, hendrerit
        vitae purus. Sed id ipsum ultricies, imperdiet justo ut, aliquet tellus.
        Aenean eget nulla eget libero blandit rutrum. Vivamus vel sapien vel leo
        placerat sollicitudin. Praesent pharetra augue eget elementum bibendum.
        Phasellus iaculis luctus commodo. Vestibulum id tristique diam. Nulla
        finibus posuere lectus id mollis. Fusce congue magna eget sem sodales
        dignissim. In mollis sed nibh non dictum. Ut purus mauris, tempus id
        elit sed, blandit rhoncus ligula. Cras lacus nulla, suscipit sed cursus
        non, maximus et ipsum. Vestibulum non ultrices augue. Donec eu mauris
        imperdiet, congue est eu, pulvinar urna. Ut lectus ligula, bibendum sit
        amet neque eu, feugiat tincidunt est. Aliquam erat volutpat. Vivamus
        dictum ex tellus. Sed at augue at ante tempus mollis sed in quam. Nullam
        quis ullamcorper dui. Vivamus non eros sit amet erat tristique
        pellentesque. Vivamus vitae malesuada nisl, vestibulum tincidunt ipsum.
        Proin eu velit ac orci mollis finibus. Nullam vitae orci semper, mattis
        dui eget, rutrum augue. Nunc arcu lorem, finibus ac hendrerit ut,
        malesuada non tellus. Etiam ut molestie nulla. Ut sodales quam quam, in
        consequat orci aliquet sed. Aenean aliquet est quis urna pellentesque,
        eget tempus leo lobortis. Maecenas sed lectus faucibus, rhoncus augue
        et, cursus ex. In hac habitasse platea dictumst. Vestibulum efficitur id
        libero sed semper. Phasellus ac ante a dui hendrerit finibus.
        Pellentesque id fermentum tortor. Vestibulum ornare, arcu eu convallis
        hendrerit, ipsum tortor faucibus risus, sed laoreet metus massa eu dui.
        Sed feugiat molestie massa nec pulvinar. Nullam pretium, ligula vel
        eleifend interdum, felis erat rutrum neque, ac fermentum mi turpis sit
        amet metus. Pellentesque ut dapibus dui. Cras rutrum lectus nunc, eget
        consectetur quam eleifend in. Pellentesque placerat, mauris eu porta
        semper, massa tortor aliquet eros, ut mattis mi nunc vitae elit.
        Pellentesque laoreet laoreet purus, ut dignissim metus viverra non. In
        pretium imperdiet ornare. Nulla quis fringilla justo. Aenean ultrices,
        elit eget pulvinar lobortis, sem urna faucibus justo, ac bibendum tellus
        erat venenatis elit. Vivamus accumsan neque vel diam rhoncus, ut lacinia
        dolor cursus. Suspendisse porta ultrices maximus. Integer eget tristique
        tellus, quis ultricies orci. Sed sed augue maximus, dapibus turpis nec,
        bibendum mauris. Sed in condimentum metus. Etiam ut eleifend lorem.
        Pellentesque malesuada, velit at convallis imperdiet, ipsum tortor
        elementum augue, sit amet aliquet est quam a ligula. Cras euismod lacus
        vitae aliquam volutpat. Aliquam erat volutpat. Integer cursus in nunc
        eget imperdiet. Nulla et eleifend neque. Fusce tincidunt ante at ornare
        consequat. Cras eu imperdiet mauris. Vivamus a nulla ut sapien faucibus
        molestie sit amet ultrices risus. Morbi dignissim lorem quis tortor
        laoreet fermentum.
      </div>
      <Footer></Footer>
    </div>
  );
};

export default App;
